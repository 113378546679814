<template>
    <Layout>
        <AccountingManagementTabs />

        <div class="card data mb-5">
            <div class="card-header bg-light bg-soft filters">
                <b-form inline>
                    <table class="table table-bordered table-nowrap mb-0">
                        <tbody>
                            <tr>
                                <td class="text-center">
                                    <div
                                        class="input-group justify-content-center my-1">
                                        <a
                                            href="javascript:void(0);"
                                            @click="navigateMonth('previous')"
                                            ><i class="bx bx-left-arrow"></i>
                                        </a>
                                        <label
                                            for=""
                                            class="input-group-label px-4 m-0"
                                            >{{
                                                `${filters.year}-${filters.month}`
                                            }}</label
                                        >
                                        <a
                                            href="javascript:void(0);"
                                            @click="navigateMonth('next')"
                                            ><i class="bx bx-right-arrow"></i>
                                        </a>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </b-form>
            </div>
            <div class="card-body">
                <div ref="tableScroller" class="table-responsive mb-3">
                    <LoadingTable
                        :cols="6"
                        :rows="50"
                        :td_small_class="`d-none`"
                        class="table-bordered"
                        v-if="loading" />
                    <table
                        class="table table-bordered table-hover table-striped table-nowrap mb-0 table-list"
                        v-else>
                        <thead class="hidden">
                            <tr class="fz bg-white">
                                <th scope="col" rowspan="2" class="fz">
                                    {{ $t('date') }}
                                </th>
                                <th scope="col" class="">
                                    <span>{{ $t('total store deposit') }}</span>
                                    <span>({{ $t('count') }})</span>
                                </th>
                                <th scope="col" class="">
                                    <span>{{
                                        $t('total store withdrawal')
                                    }}</span>
                                    <span>({{ $t('count') }})</span>
                                </th>
                                <th
                                    scope="col"
                                    class=""
                                    v-if="
                                        ['all', 'online'].includes(player_mode)
                                    ">
                                    <span>{{
                                        $t('online member charging')
                                    }}</span>
                                </th>
                                <th
                                    scope="col"
                                    class=""
                                    v-if="
                                        ['all', 'online'].includes(player_mode)
                                    ">
                                    <span>{{
                                        $t('online member exchange')
                                    }}</span>
                                </th>
                                <th
                                    scope="col"
                                    class=""
                                    v-if="
                                        ['all', 'offline'].includes(player_mode)
                                    ">
                                    <span>{{
                                        $t('offline member charging')
                                    }}</span>
                                </th>
                                <th
                                    scope="col"
                                    class=""
                                    v-if="
                                        ['all', 'offline'].includes(player_mode)
                                    ">
                                    <span>{{
                                        $t('offline member exchange')
                                    }}</span>
                                </th>
                                <th scope="col" class="">
                                    {{ $t('income') }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(r, date) in data.list"
                                :key="date"
                                :class="{ 'fw-bold': date == 'grand_total' }">
                                <td class="text-center fz">
                                    <div>
                                        {{
                                            date == 'grand_total'
                                                ? $t('grand total')
                                                : $t(
                                                      monthInWord(
                                                          new Date(
                                                              date
                                                          ).getMonth()
                                                      )
                                                  ) +
                                                  ' ' +
                                                  new Date(date).getDate() +
                                                  (this.$i18n.locale == 'ko'
                                                      ? '일'
                                                      : '')
                                        }}
                                    </div>
                                </td>
                                <td class="text-center">
                                    <a
                                        href="#"
                                        class="anchor-hover"
                                        :class="{
                                            'link-disabled':
                                                date == 'grand_total' ||
                                                r.total_agent_deposit_amount ==
                                                    0,
                                        }"
                                        :title="$t('go to agent deposit')"
                                        @click.prevent="
                                            goToList(
                                                '/agent/transaction/deposit',
                                                date,
                                                '&status=complete'
                                            )
                                        ">
                                        <span>
                                            {{
                                                numberString(
                                                    r.total_agent_deposit_amount
                                                )
                                            }}
                                        </span>
                                        <span>
                                            ({{
                                                numberString(
                                                    r.total_agent_deposit_count
                                                )
                                            }})
                                        </span>
                                    </a>
                                </td>
                                <td class="text-center">
                                    <a
                                        href="#"
                                        class="anchor-hover"
                                        :class="{
                                            'link-disabled':
                                                date == 'grand_total' ||
                                                r.total_agent_withdraw_amount ==
                                                    0,
                                        }"
                                        :title="$t('go to agent withdraw')"
                                        @click.prevent="
                                            goToList(
                                                '/agent/transaction/withdraw',
                                                date,
                                                '&status=complete'
                                            )
                                        ">
                                        <span>
                                            {{
                                                numberString(
                                                    r.total_agent_withdraw_amount
                                                )
                                            }}
                                        </span>
                                        <span>
                                            ({{
                                                numberString(
                                                    r.total_agent_withdraw_count
                                                )
                                            }})
                                        </span>
                                    </a>
                                </td>
                                <td
                                    class="text-center"
                                    v-if="
                                        ['all', 'online'].includes(player_mode)
                                    ">
                                    <a
                                        href="#"
                                        class="anchor-hover"
                                        :class="{
                                            'link-disabled':
                                                date == 'grand_total' ||
                                                r.total_online_player_deposit_amount ==
                                                    0,
                                        }"
                                        :title="$t('go to player deposit')"
                                        @click.prevent="
                                            goToList(
                                                '/player/transaction/deposit/online',
                                                date,
                                                '&status=complete'
                                            )
                                        ">
                                        <span>
                                            {{
                                                numberString(
                                                    r.total_online_player_deposit_amount
                                                )
                                            }}
                                        </span>
                                        <span>
                                            ({{
                                                numberString(
                                                    r.total_online_player_deposit_count
                                                )
                                            }})
                                        </span>
                                    </a>
                                </td>
                                <td
                                    class="text-center"
                                    v-if="
                                        ['all', 'online'].includes(player_mode)
                                    ">
                                    <a
                                        href="#"
                                        class="anchor-hover"
                                        :class="{
                                            'link-disabled':
                                                date == 'grand_total' ||
                                                r.total_online_player_withdraw_amount ==
                                                    0,
                                        }"
                                        :title="$t('go to player withdraw')"
                                        @click.prevent="
                                            goToList(
                                                '/player/transaction/withdraw/online',
                                                date,
                                                '&status=complete'
                                            )
                                        ">
                                        <span>
                                            {{
                                                numberString(
                                                    r.total_online_player_withdraw_amount
                                                )
                                            }}
                                        </span>
                                        <span>
                                            ({{
                                                numberString(
                                                    r.total_online_player_withdraw_count
                                                )
                                            }})
                                        </span>
                                    </a>
                                </td>
                                <td
                                    class="text-center"
                                    v-if="
                                        ['all', 'offline'].includes(player_mode)
                                    ">
                                    <a
                                        href="#"
                                        class="anchor-hover"
                                        :class="{
                                            'link-disabled':
                                                date == 'grand_total' ||
                                                r.total_offline_player_deposit_amount ==
                                                    0,
                                        }"
                                        :title="$t('go to player deposit')"
                                        @click.prevent="
                                            goToList(
                                                '/player/transaction/deposit/offline',
                                                date,
                                                '&status=complete'
                                            )
                                        ">
                                        <span>
                                            {{
                                                numberString(
                                                    r.total_offline_player_deposit_amount
                                                )
                                            }}
                                        </span>
                                        <span>
                                            ({{
                                                numberString(
                                                    r.total_offline_player_deposit_count
                                                )
                                            }})
                                        </span>
                                    </a>
                                </td>
                                <td
                                    class="text-center"
                                    v-if="
                                        ['all', 'offline'].includes(player_mode)
                                    ">
                                    <a
                                        href="#"
                                        class="anchor-hover"
                                        :class="{
                                            'link-disabled':
                                                date == 'grand_total' ||
                                                r.total_offline_player_withdraw_amount ==
                                                    0,
                                        }"
                                        :title="$t('go to player withdraw')"
                                        @click.prevent="
                                            goToList(
                                                '/player/transaction/withdraw/offline',
                                                date,
                                                '&status=complete'
                                            )
                                        ">
                                        <span>
                                            {{
                                                numberString(
                                                    r.total_offline_player_withdraw_amount
                                                )
                                            }}
                                        </span>
                                        <span>
                                            ({{
                                                numberString(
                                                    r.total_offline_player_withdraw_count
                                                )
                                            }})
                                        </span>
                                    </a>
                                </td>
                                <td
                                    class="text-center"
                                    :class="{
                                        'text-primary':
                                            r.total_income_per_day > 0,
                                        'text-danger':
                                            r.total_income_per_day < 0,
                                    }">
                                    {{ numberString(r.total_income_per_day) }}
                                </td>
                                <td class="mobile">
                                    <div class="cell-card">
                                        <div class="month-label fw-bold">
                                            {{
                                                date == 'grand_total'
                                                    ? $t('grand total')
                                                    : $t(
                                                          monthInWord(
                                                              new Date(
                                                                  date
                                                              ).getMonth()
                                                          )
                                                      ) +
                                                      ' ' +
                                                      new Date(date).getDate() +
                                                      (this.$i18n.locale == 'ko'
                                                          ? '일'
                                                          : '')
                                            }}
                                        </div>
                                        <div>
                                            <div
                                                class="text-end d-flex justify-content-between text-muted">
                                                <span
                                                    >{{
                                                        $t(
                                                            'total store deposit'
                                                        )
                                                    }}:</span
                                                >

                                                <a
                                                    href="#"
                                                    class="anchor-hover"
                                                    :class="{
                                                        'link-disabled':
                                                            date ==
                                                                'grand_total' ||
                                                            r.total_agent_deposit_amount ==
                                                                0,
                                                    }"
                                                    :title="
                                                        $t(
                                                            'go to agent deposit'
                                                        )
                                                    "
                                                    @click.prevent="
                                                        goToList(
                                                            '/agent/transaction/deposit',
                                                            date,
                                                            '&status=complete'
                                                        )
                                                    ">
                                                    {{
                                                        numberString(
                                                            r.total_agent_deposit_amount
                                                        )
                                                    }}
                                                    <span
                                                        v-if="
                                                            r.total_agent_deposit_amount
                                                        "
                                                        >({{
                                                            numberString(
                                                                r.total_agent_deposit_amount
                                                            )
                                                        }})</span
                                                    >
                                                </a>
                                            </div>
                                            <div
                                                class="text-end d-flex justify-content-between text-muted">
                                                <span
                                                    >{{
                                                        $t(
                                                            'total store withdrawal'
                                                        )
                                                    }}:</span
                                                >
                                                <a
                                                    href="#"
                                                    class="anchor-hover"
                                                    :class="{
                                                        'link-disabled':
                                                            date ==
                                                                'grand_total' ||
                                                            r.total_agent_withdraw_amount ==
                                                                0,
                                                    }"
                                                    :title="
                                                        $t(
                                                            'go to agent withdraw'
                                                        )
                                                    "
                                                    @click.prevent="
                                                        goToList(
                                                            '/agent/transaction/withdraw',
                                                            date,
                                                            '&status=complete'
                                                        )
                                                    ">
                                                    {{
                                                        numberString(
                                                            r.total_agent_withdraw_amount
                                                        )
                                                    }}
                                                    <span
                                                        v-if="
                                                            r.total_agent_withdraw_amount
                                                        "
                                                        >({{
                                                            numberString(
                                                                r.total_agent_withdraw_count
                                                            )
                                                        }})</span
                                                    >
                                                </a>
                                            </div>
                                            <div
                                                class="text-end d-flex justify-content-between text-muted">
                                                <span>{{ $t('income') }}:</span>
                                                <span
                                                    :class="{
                                                        'text-primary':
                                                            r.total_income_per_day >
                                                            0,
                                                        'text-danger':
                                                            r.total_income_per_day <
                                                            0,
                                                    }">
                                                    {{
                                                        numberString(
                                                            r.total_income_per_day
                                                        )
                                                    }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div
                        class="no-data-placeholder"
                        v-show="data.list.length < 1">
                        {{ $t('no data found') }}
                    </div>
                    <!-- <a
                                href="#"
                                class="scroll-pad left"
                                @mousedown="startScrolling('left')"
                                @mouseup="endScrolling"
                                @mouseleave="endScrolling"
                            ><i class="bx bx-caret-left"></i></a>
                            <a
                                href="#"
                                class="scroll-pad right"
                                @mousedown="startScrolling('right')"
                                @mouseup="endScrolling"
                                @mouseleave="endScrolling"
                            ><i class="bx bx-caret-right"></i></a> -->
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header';
import Pagination from '../../../components/pagination.vue';
import Multiselect from '@vueform/multiselect';
import Switches from 'vue-switches';
import LoadingTable from '@/components/loading-table';
import AccountingManagementTabs from '@/components/accounting-management-tabs';
import { mapActions, mapGetters } from 'vuex';
import datetime from '../../../mixins/datetime';
import number from '../../../mixins/number';
import ui from '../../../mixins/ui';
import Swal from 'sweetalert2';
export default {
    components: {
        Layout,
        PageHeader,
        Pagination,
        Switches,
        LoadingTable,
        AccountingManagementTabs,
        Multiselect,
    },
    mixins: [datetime, number, ui],
    data() {
        return {
            title: 'Monthly Report',
            filters: {
                year: 0,
                month: 0,
                username: null,
            },
            month_options: [
                { name: this.$t('January'), value: '01' },
                { name: this.$t('February'), value: '02' },
                { name: this.$t('March'), value: '03' },
                { name: this.$t('April'), value: '04' },
                { name: this.$t('May'), value: '05' },
                { name: this.$t('June'), value: '06' },
                { name: this.$t('July'), value: '07' },
                { name: this.$t('August'), value: '08' },
                { name: this.$t('September'), value: '09' },
                { name: this.$t('October'), value: '10' },
                { name: this.$t('November'), value: '11' },
                { name: this.$t('December'), value: '12' },
            ],
            days_in_month: 0,
            data: {
                list: [],
                links: [],
                current_page: 1,
                per_page: 1,
                last_page: 1,
                total: 0,
            },
            loading: false,
            scrollXDiff: 0,
            scrollYDiff: 0,
        };
    },
    watch: {
        filters: {
            handler() {
                this.initList(1);
            },
            deep: true,
        },
        testVar: {
            handler() {
                console.log(this.$refs.tableScroller);
            },
            deep: true,
        },
        '$store.state.key'() {
            this.initList(1);
        },
    },
    methods: {
        ...mapActions('report', {
            getMonthlyReport: 'monthlyReport',
        }),
        async initList(p) {
            this.loading = true;
            var pl = {
                date: `${this.filters.year}-${this.filters.month}`,
                username: this.filters.username || null,
            };
            const data = await this.getMonthlyReport(pl);
            this.data.list = [];
            if (data) {
                this.data.list = data;
            }
            this.loading = false;
        },
        goToList(route, date, additional) {
            window.open(
                `${route}?window=true&from=${date}&to=${date}${
                    additional || ''
                }${
                    this.filters.username
                        ? '&username=' + this.filters.username
                        : ''
                }`,
                route,
                'width=' + 1300 + ',height=' + (screen.height - 200)
            );
        },
        resetFilter() {
            var month = new Date().getMonth() + 1;
            this.filters.month = `${month < 10 ? '0' : ''}${month}`;
            this.filters.year = new Date().getFullYear();
            this.filters.username = null;
        },
        scroll(direction) {
            const el = this.$refs.tableScroller;

            // this.scrollXDiff = el.scrollWidth - el.scrollLeft
            // this.scrollYDiff = el.scrollHeight - el.scrollTop
            switch (direction) {
                case 'up':
                    el.scrollTo({ top: el.scrollTop - 45, behavior: 'smooth' });
                    break;
                case 'right':
                    el.scrollTo({
                        left: el.scrollLeft + 100,
                        behavior: 'smooth',
                    });
                    break;
                case 'down':
                    el.scrollTo({ top: el.scrollTop + 45, behavior: 'smooth' });
                    break;
                case 'left':
                    el.scrollTo({
                        left: el.scrollLeft - 100,
                        behavior: 'smooth',
                    });
                    break;
            }
        },
        navigateMonth(direction) {
            var date = this.getNextOrPreviousMonth(
                `${this.filters.year}-${this.filters.month}`,
                direction
            );
            this.filters.year = date.getFullYear();
            var month = date.getMonth() + 1;
            this.filters.month = `${month < 10 ? '0' : ''}${month}`;
        },
    },
    mounted() {
        this.resetFilter();
    },
};
</script>

<style scoped>
/* .table-responsive {
    scroll-snap-type: y mandatory;
    scroll-snap-points-y: repeat(300px);
    scroll-snap-type: both mandatory;
}
table {
    width: 100%; 
}
td,
th {
    min-width: 100px; 
    scroll-snap-align: start;
} */
</style>
